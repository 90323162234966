import { useState, useEffect } from "react";
import RosterCalendar from "../shared/RosterCalendar";
import {
  query,
  onSnapshot,
  addDoc,
  deleteDoc,
  doc,
  collection,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import dayjs from "dayjs";
import { useAuth } from "../contexts/AuthContext";

const useCalendar = (date, numDays) => {
  const [rosterCalendar, setRosterCalendar] = useState(
    RosterCalendar(date, numDays)
  );
  const [isLoading, setIsLoading] = useState(true);
  const [viewStartDate, setViewStartDate] = useState(date);
  const [numberOfDays, setNumberOfDays] = useState(numDays);

  const { currentUser, profile } = useAuth();

  dayjs.locale("nb");

  const dateQuery = (startDate, numberOfDays, collectionName) => {
    // Use clone() to avoid mutating the original dayjs instance
    return query(
      collection(db, collectionName),
      where("date", ">=", startDate.clone().add(-1, "day").toDate()),
      where("date", "<", startDate.clone().add(numberOfDays, "day").toDate())
    );
  };

  useEffect(() => {
    // We set isLoading to true whenever viewStartDate / numberOfDays changes,
    // so the UI can show a loading spinner or similar.
    setIsLoading(true);

    // Create a fresh RosterCalendar each time user changes date or # of days
    const newCalendar = RosterCalendar(viewStartDate, numberOfDays);

    // We'll store the data here
    let dayoverridesData = [];
    let rostersData = [];

    // Track whether we've received the *initial* snapshot for each collection
    let dayoverridesInitialized = false;
    let rostersInitialized = false;

    // Because we want dayoverrides inserted first, we define a helper function
    // to check if both are loaded. If so, we do the insertion.
    const handleInitialLoadIfReady = () => {
      if (dayoverridesInitialized && rostersInitialized) {
        // 1) Insert dayoverrides first
        dayoverridesData.forEach((override) => {
          newCalendar.insertDayOverride(override);
        });

        // 2) Then insert rosters
        rostersData.forEach((roster) => {
          newCalendar.insertRoster(roster);
        });

        // 3) Now update UI
        setRosterCalendar({ ...newCalendar });
        setIsLoading(false);
      }
    };

    // --- Subscribe to dayoverrides ---
    const overridesQuery = dateQuery(viewStartDate, numberOfDays, "dayoverrides");
    const unsubscribeDayoverrides = onSnapshot(overridesQuery, (snapshot) => {
      // If we haven't yet done the initial load for dayoverrides, 
      // handle them as a batch:
      if (!dayoverridesInitialized) {
        dayoverridesData = snapshot.docs.map((docSnap) => ({
          ...docSnap.data(),
          id: docSnap.id,
        }));
        dayoverridesInitialized = true;
        handleInitialLoadIfReady();
      } else {
        // For subsequent changes after the initial load:
        snapshot.docChanges().forEach((change) => {
          const changedDoc = { ...change.doc.data(), id: change.doc.id };
          if (change.type === "added") {
            // Insert directly into the *existing* calendar
            newCalendar.insertDayOverride(changedDoc);
          } else if (change.type === "removed") {
            newCalendar.removeDayOverride(changedDoc);
          }
        });
        setRosterCalendar({ ...newCalendar });
      }
    });

    // --- Subscribe to rosters ---
    const rostersQuery = dateQuery(viewStartDate, numberOfDays, "rosters");
    const unsubscribeRosters = onSnapshot(rostersQuery, (snapshot) => {
      if (!rostersInitialized) {
        rostersData = snapshot.docs.map((docSnap) => ({
          ...docSnap.data(),
          id: docSnap.id,
        }));
        rostersInitialized = true;
        handleInitialLoadIfReady();
      } else {
        // Subsequent changes
        snapshot.docChanges().forEach((change) => {
          const changedDoc = { ...change.doc.data(), id: change.doc.id };
          if (change.type === "added") {
            newCalendar.insertRoster(changedDoc);
          } else if (change.type === "removed") {
            newCalendar.removeRoster(changedDoc);
          }
        });
        setRosterCalendar({ ...newCalendar });
      }
    });

    // Cleanup
    return () => {
      unsubscribeDayoverrides();
      unsubscribeRosters();
    };
  }, [viewStartDate, numberOfDays]);

  // -----------------------------------------------------
  // The rest: helper functions remain mostly unchanged
  // -----------------------------------------------------
  
  function deleteRoster(roster) {
    console.log("Delete Roster", roster);
    deleteDoc(doc(db, "rosters", roster.id)).catch((error) => {
      console.error("Unable to delete roster", error);
    });
  }

  function addRoster(date, slot, forUser) {
    if (!forUser) forUser = profile;

    const docData = {
      mappedToPhone: forUser.phoneNumber,
      assignedToEmail: forUser.email,
      assignedToFirstName: forUser.firstName,
      assignedToLastName: forUser.lastName,
      assignedToPhoneNumber: forUser.phoneNumber,
      updatedBy: currentUser.uid,
      updatedByEmail: currentUser.email,
      updatedByFirstName: profile.firstName,
      updatedByLastName: profile.lastName,
      date: date.toDate(),
      start: slot.start,
      end: slot.end,
    };

    addDoc(collection(db, "rosters"), docData).then((result) => {
      console.log(result);
    });
  }

  function insertOverride(day, override) {
    if (day.getId()) deleteOverride(day);
    addDoc(collection(db, "dayoverrides"), {
      date: day.date.toDate(),
      ...override,
    }).catch((error) => {
      console.error("Unable to add override", error);
    });
  }

  function deleteOverride(day) {
    console.log("Delete override with ID ", day.getId());
    deleteDoc(doc(db, "dayoverrides", day.getId())).catch((error) => {
      console.error("Unable to delete override", error);
    });
  }

  async function closeDay(day, reason) {
    console.log("closing", day, reason, day.getAllRosters());

    const dayStr = day.date.format("dddd DD. MMMM");
    const recipients = Array.from(
      new Set(
        day
          .getAllRosters()
          .map((r) => r.assignedToPhoneNumber)
          .filter((p) => p)
      )
    ).join(",");

    // Remove all rosters
    day.getAllRosters().forEach((roster) => {
      deleteRoster(roster);
    });

    // Insert a day override that marks it closed
    const closeData = {
      date: day.date.toDate(),
      isOpen: false,
      reason: reason,
    };
    addDoc(collection(db, "dayoverrides"), closeData).then(() => {
      console.log("Added close day to db");
    });

    // Optionally, send out an SMS
    const docData = {
      type: "SMS",
      messageText: `Bakken er meldt stengt ${dayStr} av Bak-Olsen grunnet dårlig vær`,
      recipients,
    };
    return addDoc(collection(db, "messageOutbox"), docData);
  }

  return {
    rosterCalendar,
    viewStartDate,
    setViewStartDate,
    setNumberOfDays,
    addRoster,
    deleteRoster,
    closeDay,
    insertOverride,
    deleteOverride,
    isLoading,
  };
};

export default useCalendar;
